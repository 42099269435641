:root {
  --orange: #ff821c;
  --myGray: rgb(35, 37, 51);
  --lightGray: rgb(221, 221, 221);
  --desBlue: #1FB0E5;
  --darkBlue: #1c2b45;
  /* ViewPorts data*/
  --wideDesctop: 1350px;
  --smallDesctop: 1160px;
  --tablet: 900px;
  --mobile: 648px;
}