.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}

*{
  font-family: 'Montserrat-Semibold';
}

div, nav, section{
  box-sizing: border-box;
}
