@font-face {
    font-family: 'LiberationSerif-Bold';
    src: local('LiberationSerif-Bold'),
        url(./static/fonts/LiberationSerif-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'LiberationSerif-BoldItalic';
    src: local('LiberationSerif-BoldItalic'),
        url(./static/fonts/LiberationSerif-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'LiberationSerif-Regular';
    src: local('LiberationSerif-Regular'),
        url(./static/fonts/LiberationSerif-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Black';
    src: local('Montserrat-Black'),
        url(./static/fonts/Montserrat-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: local('Montserrat-BlackItalic'),
        url(./static/fonts/Montserrat-BlackItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'),
        url(./static/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: local('Montserrat-BoldItalic'),
        url(./static/fonts/Montserrat-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: local('Montserrat-ExtraBold'),
        url(./static/fonts/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: local('Montserrat-ExtraLight'),
        url(./static/fonts/Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: local('Montserrat-Italic'),
        url(./static/fonts/Montserrat-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Light';
    src: local('Montserrat-Light'),
        url(./static/fonts/Montserrat-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-LightItalic';
    src: local('Montserrat-LightItalic'),
        url(./static/fonts/Montserrat-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'),
        url(./static/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: local('Montserrat-MediumItalic'),
        url(./static/fonts/Montserrat-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'),
        url(./static/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'),
        url(./static/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: local('Montserrat-SemiBoldItalic'),
        url(./static/fonts/Montserrat-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: local('Montserrat-Thin'),
        url(./static/fonts/Montserrat-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Serif_Regular';
    src: local('Serif_Regular'),
        url(./static/fonts/PT\ Astra\ Serif_Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Serif_Italic';
    src: local('Serif_Italic'),
        url(./static/fonts/PT\ Astra\ Serif_Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Serif_Bold';
    src: local('Serif_Bold'),
        url(./static/fonts/PT\ Astra\ Serif_Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Serif_Bold\ Italic';
    src: local('Serif_Bold\ Italic'),
        url(./static/fonts/PT\ Astra\ Serif_Bold\ Italic.ttf) format('truetype');
}