.bannerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 80px 0;
}

.content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    margin: 10px 0;
    font-size: 24px;
    font-family: "Montserrat-Medium";
    text-align: center;
}


.imgs {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.secondImg {
    display: none;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgWrapper:hover .secondImg {
    display: block;
}

.imgWrapper:hover .mainImg {
    display: none;
}

.imgWrapper img {
    width: 100%;

}

@media (max-width: 1350px) {
    .text {
        font-size: 20px;
    }

    .imgWrapper img {
        width: 80%;
    }
}

@media (max-width: 1160px) {
    .text {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .bannerWrapper {
        flex-direction: column;
    }

    .content {
        width: 100%;
        margin-bottom: 40px;
    }

    .imgs {
        width: 100%;
    }

    .imgWrapper img {
        width: 80%;
    }
}

@media (max-width: 648px) {

}